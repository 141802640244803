import { ReactElement, useState, useEffect, useMemo, useCallback } from 'react';
import { actions, RootState, ThunkDispatchType, PersonalNote, AnyNote } from '../store';
import { InfoCardFields } from '../utils/types';
import { matchPath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getPersonalNoteTemplate } from '../store/templates';
import NoteOnboarding from '../components/NoteOnboarding';
import { NoteType, EXEC_CARD_TYPES, EVENT_TYPE } from '../constants';
import CardsPageHeader from '../components/Common/CardsPageHeader';
import { PAGE_URL } from '../constants';
import InfoCardContainer from '../components/InfoCard/InfoCardContainer';
import { trackEventWithExtra } from '../utils/appAnalyticsUtils';
import { NO_NOTES_GRAPHIC_SRC } from '../resourceUrls';
import CabinetPage from '../components/Common/CabinetPage';
import { CabButton, CabIcon } from '@CabComponents';
import { CABINET_ICONS, EMOJIS } from '../icons';
import { CabModal } from '@CabComponents/CabModal';
import { Box, Grid, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import CabSpinner from '@CabComponents/CabSpinner';
import { IoArrowBack } from 'react-icons/io5';


const personalCopy = {
  title: 'Let\'s organize key details about family members:',
  icon: EMOJIS.PERSONAL,
  icon_url: CABINET_ICONS.FAMILY.icon_url,
  listCaption: 'Here\'s what we\'ll help you track:',
  info: [
    'Family members\' names and birthdays',
    'Family members\' travel details and preferences', 
    'Any additional notes'
  ]
};


type RouteParams = { leaderId: string; };


export const Personal = (): ReactElement => {

  const leaders = useSelector((state: RootState) =>  state.leaders);
  const personalNotes = useSelector((state: RootState) =>  state.personalNotes);

  const params = useParams<RouteParams>();
  const location = useLocation();
  const navigate = useNavigate();

  const dispatch = useDispatch<ThunkDispatchType>();
  const fetchPersonalNotes = useCallback((leader: number) => 
    dispatch(actions.personal.fetchPersonalNotes(leader)), [dispatch]);
  const createPersonalNote = (note: PersonalNote) => dispatch(actions.personal.createPersonalNote(note));
  const updatePersonalNote = (note: PersonalNote) => dispatch(actions.personal.updatePersonalNote(note));
  const deletePersonalNote = (noteId: number) => dispatch(actions.personal.deletePersonalNote(noteId));

  const currentLeader = useMemo(() => {
    const foundLeader = leaders.leaders.find(leader => leader.id.toString() === params.leaderId);
    if (leaders.loaded && !foundLeader) {
      navigate(PAGE_URL.DASHBOARD);
    }
    return foundLeader;
  }, [leaders.leaders, leaders.loaded, params.leaderId, navigate]);

  useEffect((): void => {
    if (currentLeader && currentLeader.id > 0) {
      fetchPersonalNotes(currentLeader.id);
    }
  }, [fetchPersonalNotes, currentLeader, navigate]);

  const [newPersonalNote, setNewPersonalNote] = useState<PersonalNote>(getPersonalNoteTemplate());
  const [addPersonalMode, setAddPersonalMode] = useState(false);
  const [idToDelete, setIdToDelete] = useState(-1);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);

  useEffect((): void => {
    const matchUrl = matchPath(location.pathname, "/executive/:id/personal");
    // checking URL to hotfix modal opening when it shouldn't
    if (personalNotes.loaded && personalNotes.notes.length === 0 && matchUrl) {
      if (!currentLeader?.is_shared) {
        setShowOnboarding(true);
      }
    }
  }, [personalNotes.loaded, personalNotes.notes.length, currentLeader, location.pathname]);


  const handleOpenDeleteAlert = (personalNoteId: number): (() => void) => 
    (): void => {
      setIdToDelete(personalNoteId);
      setDeleteAlertOpen(true);
    };

  const handleConfirmDelete = (): void => {
    setDeleteAlertOpen(false);
    deletePersonalNote(idToDelete);
    trackEventWithExtra({ eventName: EVENT_TYPE.DELETE_EXEC_CATEGORY_CARD, extra: {category: EXEC_CARD_TYPES.FAMILY} });
  };

  const handleSavePersonalNote = (personalNote: AnyNote): Promise<void> => {
    if (addPersonalMode && currentLeader) {
      trackEventWithExtra({ 
        eventName: EVENT_TYPE.CREATE_EXEC_CATEGORY_CARD, 
        extra: {category: EXEC_CARD_TYPES.FAMILY} 
      });
      return createPersonalNote({...personalNote as PersonalNote, leader: currentLeader.id})
        .then((): void => {
          setAddPersonalMode(false);
        });
    } else {
      trackEventWithExtra({ 
        eventName: EVENT_TYPE.UPDATE_EXEC_CATEGORY_CARD, 
        extra: {category: EXEC_CARD_TYPES.FAMILY} 
      });
      return updatePersonalNote(personalNote as PersonalNote);
    }
  };

  const handleAddNewPersonalNote = (): void => {
    setNewPersonalNote({...newPersonalNote});
    setAddPersonalMode(true);
  };

  const handleCancelAdd = (): void => {
    setAddPersonalMode(false);
  };

  const handleOnboardingClose = (): void => {
    setShowOnboarding(false);
  };

  const loaded = personalNotes.loaded;
  const showNotes = 
  currentLeader && loaded && personalNotes.notes.length > 0 && personalNotes.notes[0].leader === currentLeader.id;

  const infoFields: InfoCardFields<PersonalNote>[] = [
    {label: 'Relation', key: 'relation'},
    {label: 'Address', key: 'address'},
    {label: 'Known Traveler', key: 'known_traveler'}, 
    {label: 'Birthday', key: 'birthday'},
    {label: 'Health Insurance', key: 'health_insurance'},
    {label: 'Airline Seat Preference', key: 'airline_seat_preference'},
    {label: 'General Notes', key: 'notes'}
  ];

  const hasEditPermission = currentLeader?.permissions.standard.personal.edit;

  const renderPersonalCards = (): ReactElement => (
    <Grid container spacing={2}>
      <Grid item hidden={true}>
        <InfoCardContainer note={newPersonalNote} saveNote={handleSavePersonalNote} addMode={addPersonalMode} 
          title="Person" currentLeader={currentLeader}
          openDeleteAlert={handleOpenDeleteAlert(newPersonalNote.id)} noteType={NoteType.PERSONAL} 
          infoFields={infoFields} cancelAdd={handleCancelAdd}/>
      </Grid>
      {showNotes && personalNotes.notes.map((personalNote: PersonalNote): ReactElement => 
        <Grid item xs={12} md={6} key={personalNote.id}>
          <Box height='100%'>
            <InfoCardContainer note={personalNote} saveNote={handleSavePersonalNote} addMode={false} 
              title="Person" currentLeader={currentLeader}
              openDeleteAlert={handleOpenDeleteAlert(personalNote.id)} noteType={NoteType.PERSONAL} 
              infoFields={infoFields}/>
          </Box>
        </Grid>
      )}
    </Grid>
  );
  
  const renderPlaceholder = (): ReactElement | null => {
    if (loaded && personalNotes.notes.length === 0) {
      return ( 
        <Box display='flex' width='100%' flexDirection='column' height="100%" alignItems="center" 
          justifyContent="center" gap={1}>
          <Box component={'img'} src={NO_NOTES_GRAPHIC_SRC} alt="No notes yet" />
          <Typography variant='h5'>No Family Members have been added yet</Typography>
          {hasEditPermission &&
             <CabButton onClick={handleAddNewPersonalNote}>
               Add the first Family Member
             </CabButton>
          }
        </Box>
      );
    } else {
      return null;
    }
  };

  const onBack = (): void => navigate(`${PAGE_URL.EXECUTIVE}/${currentLeader?.id}`);

  const renderSpinnerOrContent = (): ReactElement => {
    if (!loaded) {
      return <Box display='flex' height="100%" alignItems="center" justifyContent="center">
        <CabSpinner scale={4}/>
      </Box>;
    } else {
      return (
        <>
          <Box width='100%' display='flex' flexDirection='column' padding={2} gap={2}>
            {currentLeader && (
              <Box width='100%' display='flex'>
                <CabButton
                  onClick={onBack}
                  icon={<CabIcon Icon={IoArrowBack} />}
                  buttonType="tertiary"
                  color="accent"
                  size="small"
                >
                  Back
                </CabButton>
              </Box>
            )}
            <Box>
              {renderPlaceholder()}
              {renderPersonalCards()}
            </Box>
          </Box>
          <CabModal
            open={deleteAlertOpen}
            onClose={() => setDeleteAlertOpen(false)}
            isAlert
            closeIcon
            title='Warning'
            text='Are you sure you want to delete this family member?'
            noFullScreen
            actionButtons={
              <>
                <CabButton buttonType='tertiary' color='primary' onClick={() => setDeleteAlertOpen(false)}>
                  Cancel
                </CabButton>
                <CabButton onClick={handleConfirmDelete}>
                  Yes, delete them
                </CabButton>
              </>
            }
          />
        </>
      );
    }
  };

  return (
    <CabinetPage
      pageName={'ExecFamily'}
      headerContent={<>
        {currentLeader && 
            <CardsPageHeader 
              title="Family" 
              addCard={hasEditPermission ? handleAddNewPersonalNote : undefined} 
            />
        }
      </>}
    >
      {renderSpinnerOrContent()}
      <CabModal
        closeOnBackdropClick
        open={showOnboarding}
        onClose={handleOnboardingClose}
        title={(
          <Box display="flex" alignItems="center" gap={1} paddingTop={2}>
            <Box component={'img'} src={personalCopy.icon_url} alt={'faily member'} height={40} />
            {personalCopy.title}
          </Box>
        )}
        actionButtons={
          <CabButton size="large" buttonType="primary" onClick={handleOnboardingClose}>
            Got it!
          </CabButton>
        }
        sx={{ ".MuiPaper-root": { minWidth: '500px' } }}
      >
        <NoteOnboarding copy={personalCopy} />
      </CabModal>

    </CabinetPage>
  );
};

export default Personal;